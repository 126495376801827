// UI
import { InputWithFloatingLabel } from 'components/Inputs/InputWithFloatingLabel';
import { DeleteButton } from 'components/Buttons';

// Icons
import sort from 'images/sort_dnd_2.svg';

// Styles
import styles from './LinkItem.module.css';

const LinkItem = ({
	outlets,
	label,
	link,
	item,
	updateOutletLink,
	deleteOutletLink,
	updateSocialLink,
	deleteSocialLink,
}) => {
	return (
		<div className={styles.container}>
			{outlets && <img src={sort} alt="dnd" draggable />}
			<InputWithFloatingLabel
				customStyles={outlets ? { width: '370px' } : { width: '395px' }}
				placeholder={label}
				value={link}
				onChange={
					outlets
						? (value) => updateOutletLink(item.id, value)
						: (value) => updateSocialLink(value)
				}
			/>
			<DeleteButton
				onDeleteHandler={
					outlets ? () => deleteOutletLink(item.id) : deleteSocialLink
				}
			/>
		</div>
	);
};

export default LinkItem;
