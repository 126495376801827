// Core
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { LangContext } from 'contexts/LangContext';

//
import { outletsLabels } from 'constants/outletsLabels';

// UI
import LinkItem from '../LinkItem/LinkItem';

// Styles
import styles from './Outlets.module.css';

const Outlets = ({
	data,
	updateOutletLink,
	deleteOutletLink,
	dragItem,
	dragOverItem,
	handleSort,
}) => {
	const { lang } = useContext(LangContext);

	const getLabelFromUrl = (url) => {
		const found = outletsLabels.find(({ keyword }) => url.includes(keyword));

		return found ? found.label : lang === 'ru' ? 'ссылка' : 'link';
	};

	return (
		<div className={styles.container}>
			<h4 className={styles.title}>
				<FormattedMessage id="rod.smartlink_form.outlets" />
			</h4>
			<div className={styles.linksContainer}>
				{data.links.map((item, index) => (
					<div
						key={item.id}
						draggable
						onDragStart={() => (dragItem.current = index)}
						onDragEnter={() => (dragOverItem.current = index)}
						onDragEnd={handleSort}
						onDragOver={(e) => e.preventDefault()}
					>
						<LinkItem
							outlets
							label={getLabelFromUrl(item.url)}
							link={item.url}
							item={item}
							updateOutletLink={updateOutletLink}
							deleteOutletLink={deleteOutletLink}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default Outlets;
