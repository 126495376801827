// Core
import { FormattedMessage } from 'react-intl';

// UI
import { FormInput } from 'components/Form/FormInput';

// Styles
import styles from './General.module.css';

const General = ({ data, changeField, errors }) => {
	return (
		<>
			<h4 className={styles.title}>
				<FormattedMessage id="rod.smartlink_form.general" />
			</h4>
			<div className={styles.container}>
				<FormInput
					type="muiInput"
					name="release_name"
					onChange={changeField}
					errors={errors}
					data={data}
					label={<FormattedMessage id="rod.smartlink_form.general.release" />}
				/>
				<FormInput
					type="muiInput"
					name="artist_name"
					onChange={changeField}
					errors={errors}
					data={data}
					label={<FormattedMessage id="rod.smartlink_form.general.artist" />}
				/>
				<FormInput
					type="muiInput"
					name="url"
					onChange={changeField}
					errors={errors}
					data={data}
					label={<FormattedMessage id="rod.smartlink_form.general.urls" />}
				/>
			</div>
		</>
	);
};

export default General;
