import React from 'react';
import { FormattedMessage } from 'react-intl';

//Components
import TableElement from './TableElement/TableElement';
import { Skeleton, EmptyData } from 'components';
import { Pagination } from 'material-design/components';

// Styles
import styles from './SmartlinksList.module.css';

const SmartlinksList = ({
	loading,
	page,
	setPage,
	total,
	smartlinks,
	onEditSmartlink,
	onPreviewSmartlink,
	onDeleteSmartlink,
	disable,
}) => {
	const itemsOnPage = 10;

	return (
		<div
			className={styles.wrapper}
			style={
				loading
					? { minHeight: '287px' }
					: !loading && !smartlinks.length
					? { boxShadow: 'none', backgroundColor: 'transparent' }
					: {}
			}
		>
			{loading ? (
				<Skeleton />
			) : smartlinks.length > 0 ? (
				<>
					<div className={styles.table}>
						<div className={styles.tableHeading}>
							<p className={styles.tableHeadingTitle}>
								<FormattedMessage id="rod.for_all.title" />
							</p>
							<p className={styles.tableHeadingTitle}>
								<FormattedMessage id="rod.release.create.step.submit.release_date" />
							</p>
							<p
								className={styles.tableHeadingTitle}
								style={{ textAlign: 'end' }}
							>
								<FormattedMessage id="rod.field.admin.actions" />
							</p>
						</div>
						<ul className={styles.tableList}>
							{smartlinks.map((item, index) => (
								<TableElement
									key={index}
									item={item}
									index={index}
									page={page}
									onEditSmartlink={onEditSmartlink}
									onPreviewSmartlink={onPreviewSmartlink}
									onDeleteSmartlink={onDeleteSmartlink}
									disable={disable}
								/>
							))}
						</ul>
					</div>
					{total > itemsOnPage && (
						<div className={styles.pagination}>
							<Pagination
								page={page}
								total={total}
								paginate={(pageNumber) => {
									if (pageNumber !== page) {
										setPage(pageNumber);
									}
								}}
								items_on_page={itemsOnPage}
							/>
						</div>
					)}
				</>
			) : (
				<div className={styles.empty}>
					<EmptyData
						title="rod.no_smartlink_data"
						customStyles={{
							height: '60vh',
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default SmartlinksList;
