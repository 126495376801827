// Core
import React from 'react';

// UI
import { CopyButton, CustomButton, DeleteButton } from 'components';

// Styles
import styles from './TableElement.module.css';

const TableElement = ({
	item,
	index,
	page,
	onEditSmartlink,
	onPreviewSmartlink,
	onDeleteSmartlink,
	disable,
}) => {
	return (
		<li className={styles.tableElement}>
			<div className={styles.infoWrapper}>
				<div className={styles.place}>{index + 1 + (page - 1) * 10}</div>
				{item.release_image && (
					<div className={styles.cover}>
						<img src={item.release_image} alt="" />
					</div>
				)}
				<div>
					<p
						className={`${styles.text} ${styles.title}`}
						title={item.release_name}
					>
						{item.release_name}
					</p>
					<p className={styles.subtitle} title={item.artist_name}>
						{item.artist_name}
					</p>
				</div>
			</div>
			<span className={styles.text}>{item.date}</span>
			<div className={styles.actions}>
				<CopyButton data={item.url} dark />
				<CustomButton
					disable={disable}
					mode="edit"
					onHandle={() => onEditSmartlink(item)}
				/>
				<CustomButton
					disable={disable}
					mode="preview"
					onHandle={() => onPreviewSmartlink(item)}
				/>
				<DeleteButton
					disable={disable}
					onDeleteHandler={() => onDeleteSmartlink(item)}
				/>
			</div>
		</li>
	);
};

export default TableElement;
