// Core
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PoseGroup } from 'react-pose';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash.debounce';

// Services
import { accounts } from 'services';

// UI
import { Modal, Shade } from './Posed';
import BaseModal from '../BaseModal';
import { CopyButton, DeleteButton, Loading } from 'components';
import { Container } from 'components/Grid';
import General from './SmartlinkForm/General/General';
import Outlets from './SmartlinkForm/Outlets/Outlets';
import Socials from './SmartlinkForm/Socials/Socials';
import Themes from './SmartlinkForm/Themes/Themes';
import ModalControls from './ModalControls/ModalControls';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Styles
import styles from './SmartlinkModal.module.css';

/* const test = {
	social_links_enabled: true,
	type: 'release',
	hex: '3wnjq',
	user_id: 3358689,
	creator_id: 3358689,
	release_image:
		'https://i.scdn.co/image/ab67616d0000b2736ca3967e1dd125b10a96a2f7',
	release_name: 'Русская береза',
	artist_name: 'Сергей Кристинин',
	artist_official: 'https://music.yandex.ru/album/22266818',
	artist_twitter: 'http://vkmusic.com/musiclink',
	artist_facebook: 'https://music.yandex.ru/album/22266818',
	artist_instagram: 'https://music.yandex.ru/album/22266818',
	artist_youtube: 'https://music.yandex.ru/album/22266818',
	artist_vk: 'https://music.yandex.ru/album/22266818',
	player_enabled: 1,
	upc: '4741534662567',
	soundcloud_preview: null,
	theme: null,
	video_enabled: 1,
	links: [
		{
			id: 10362374,
			bandlink_id: 1528490,
			url: 'https://music.yandex.ru/album/22266818',
			temp_url: null,
			upc: '4741534662567',
			order: 1,
			tracks: [],
			direct_link: 0,
			direct_link_time: null,
			itunes_player: 0,
			deleted_at: null,
			created_at: '2025-04-02T15:21:50.000000Z',
			updated_at: '2025-04-02T15:21:50.000000Z',
		},
		{
			id: 10362372,
			bandlink_id: 1528490,
			url:
				'https://open.zvuk.com/cLQ0?pid=Bandlink&c=bandlink_1&is_retargeting=true&af_click_lookback=7d&af_dp=zvuk://&af_web_dp=https://zvuk.com/release/23677866&action_name=open-release&id=23677866',
			temp_url: null,
			upc: '4741534662567',
			order: 2,
			tracks: [],
			direct_link: 0,
			direct_link_time: null,
			itunes_player: 0,
			deleted_at: null,
			created_at: '2025-04-02T15:21:49.000000Z',
			updated_at: '2025-04-02T15:21:50.000000Z',
		},
	],
	url: 'https://band.link/3wnjq',
};
 */
const SmartlinkModal = ({
	title,
	subtitle,
	onClose,
	accountId,
	releaseId,
	smartlinkData,
	setSmartlinkData,
	generateSmartlinkForRelease,
	getSmartlinkById,
	updateSmartlinkById,
	getSmartlinkOutlets,
	repertoireRelease,
	onDeleteSmartlink,
}) => {
	const [loading, setLoading] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const [iframeKey, setIframeKey] = useState(0);
	const [iframeLoading, setIframeLoading] = useState(false);
	const [smartlinkFormData, setSmartlinkFormData] = useState(null);
	const [pendingPayload, setPendingPayload] = useState(null);
	const [availableOutlets, setAvailableOutlets] = useState([]);
	const [errors, setErrors] = useState({});

	const dragItem = useRef(null);
	const dragOverItem = useRef(null);

	useEffect(() => {
		setIsVisible(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const willUnmount = (e) => {
		setIsVisible(false);
		setTimeout(() => {
			onClose();
		}, 300);
	};

	const cleanErrorsField = (field) => {
		delete errors[field];
		setErrors(errors);
	};

	// Debounced API call (only fires after user stops typing)
	const debouncedUpdate = useCallback(
		debounce((payload) => {
			setIframeLoading(true);
			updateSmartlinkById(accountId, releaseId, smartlinkData.id, payload)
				.then((res) => {
					setSmartlinkFormData(res.data.data);
					setIframeKey((prev) => prev + 1); // Update iframe **only after API success**
				})
				.catch((e) => console.error('Error updating Smartlink:', e))
				.finally(() =>
					setTimeout(() => {
						setIframeLoading(false);
					}, 500)
				);
		}, 500), // delay to prevent excessive requests
		[accountId, releaseId, smartlinkData.id]
	);

	// Handles input changes but doesn't trigger API immediately
	const changeField = (field) => (e) => {
		let updatedData = { ...smartlinkFormData };

		switch (field) {
			case 'theme':
				updatedData[field] = e.id;
				break;
			default:
				updatedData[field] = e.target.value;
				break;
		}

		setSmartlinkFormData(updatedData);
		setPendingPayload(updatedData);
	};

	const deleteOutletLink = (id) => {
		setSmartlinkFormData((prevState) => {
			const updatedData = {
				...prevState,
				links: prevState.links.filter((link) => link.id !== id),
			};
			setPendingPayload(updatedData);
			return updatedData;
		});
	};

	const updateOutletLink = (id, newUrl) => {
		setSmartlinkFormData((prevState) => {
			const updatedData = {
				...prevState,
				links: prevState.links.map((link) =>
					link.id === id ? { ...link, url: newUrl } : link
				),
			};
			setPendingPayload(updatedData);
			return updatedData;
		});
	};

	const deleteSocialLink = (key) => {
		setSmartlinkFormData((prevState) => {
			const updatedData = { ...prevState };
			updatedData[key] = null;
			setPendingPayload(updatedData);
			return updatedData;
		});
	};

	const updateSocialLink = (key, newUrl) => {
		setSmartlinkFormData((prevState) => {
			const updatedData = {
				...prevState,
				[key]: newUrl,
			};
			setPendingPayload(updatedData);
			return updatedData;
		});
	};

	const handleSort = () => {
		let items = [...smartlinkFormData.links];
		const draggedItemContent = items.splice(dragItem.current, 1)[0];
		items.splice(dragOverItem.current, 0, draggedItemContent);
		dragItem.current = null;
		dragOverItem.current = null;

		// Update order field based on new index
		const updatedItems = items.map((item, index) => ({
			...item,
			order: index,
		}));

		setSmartlinkFormData((prevState) => {
			const updatedData = { ...prevState, links: updatedItems };
			setPendingPayload(updatedData);
			return updatedData;
		});
	};

	const handleSwitcher = (value) => {
		setErrors({});
		setSmartlinkFormData((prevState) => {
			const updatedData = { ...prevState, social_links_enabled: value };
			setPendingPayload(updatedData);
			return updatedData;
		});
	};

	// Triggers API call only when user stops typing
	useEffect(() => {
		if (pendingPayload) {
			debouncedUpdate({
				...pendingPayload,
				social_links_enabled: pendingPayload.social_links_enabled ?? false,
			});
		}
	}, [pendingPayload, debouncedUpdate]);

	useEffect(() => {
		setLoading(true);
		if (typeof smartlinkData === 'boolean') {
			try {
				generateSmartlinkForRelease(accountId, releaseId).then((res) => {
					if (res.data.data.id) {
						getSmartlinkById(accountId, releaseId, res.data.data.id)
							.then((result) => {
								setSmartlinkFormData({
									...result.data.data,
									theme: result.theme ?? 'light',
									social_links_enabled:
										result.data.data.social_links_enabled ?? false,
								});
								if (repertoireRelease) {
									accounts
										.getReleaseInfoForBrowsing(accountId, releaseId)
										.then((info) => {
											info = info.data.data;
											if (info.hasOwnProperty('smart_link')) {
												setSmartlinkData(
													info.smart_link ? info.smart_link : true
												);
											}
										})
										.catch((error) => {
											console.error('Error', error);
										});
								}
							})
							.catch()
							.finally(() => setLoading(false));
					}
				});
			} catch (error) {
				console.error('Error while generate smartlink');
			}
		} else {
			getSmartlinkById(accountId, releaseId, smartlinkData.id)
				.then((res) =>
					setSmartlinkFormData({
						...res.data.data,
						theme: res.theme ?? 'light',
						social_links_enabled: res.data.data.social_links_enabled ?? false,
					})
				)
				.catch()
				.finally(() => setLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getSmartlinkOutlets().then((res) => setAvailableOutlets(res.data.data));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<BaseModal className={styles.modal}>
			<PoseGroup>
				{isVisible && [
					<Shade key="shade" className={styles.overlay} />,
					<div key="iframe-container" className={styles.iframeContainer}>
						{iframeLoading ? (
							<Loading />
						) : (
							smartlinkFormData?.url && (
								<iframe
									key={iframeKey} // Ensures iframe reloads with fresh data
									src={smartlinkFormData.url}
									className={styles.smartlinkIframe}
									frameBorder="0"
									allowFullScreen
									title={`Smartlink preview for ${
										smartlinkFormData.release_name || 'release'
									}`}
								></iframe>
							)
						)}
					</div>,
					<Modal
						key="modal"
						style={{
							marginLeft: 'auto',
						}}
					>
						<Container className={styles.container} fluid>
							<div>
								<div
									md={12 - 4}
									onClick={(e) => {
										willUnmount(e);
									}}
								/>
								<div className={styles.bodyContainer}>
									{loading ? (
										<Loading />
									) : (
										<>
											<div className={styles.bodyWrapper}>
												<div className={styles.bodyTopWrapper}>
													<div></div>
													<CloseIcon
														onClick={(e) => {
															willUnmount(e);
														}}
														style={{ cursor: 'pointer' }}
													/>
												</div>
												<div className={styles.bodyHeader}>
													{title && (
														<div className={styles.bodyTitle}>
															<FormattedMessage id={title} />
															<div className={styles.titleButtons}>
																<CopyButton data={smartlinkFormData.url} dark />
																<DeleteButton
																	onDeleteHandler={() =>
																		onDeleteSmartlink(smartlinkData)
																	}
																/>
															</div>
														</div>
													)}
													{subtitle && (
														<div className={styles.bodySubtitle}>
															<FormattedMessage id={subtitle} />
														</div>
													)}
												</div>
												<General
													data={smartlinkFormData}
													changeField={changeField}
													errors={errors}
												/>
												<Outlets
													data={smartlinkFormData}
													dragItem={dragItem}
													dragOverItem={dragOverItem}
													handleSort={handleSort}
													updateOutletLink={updateOutletLink}
													deleteOutletLink={deleteOutletLink}
												/>
												<Socials
													data={smartlinkFormData}
													handleSwitcher={handleSwitcher}
													updateSocialLink={updateSocialLink}
													deleteSocialLink={deleteSocialLink}
												/>
												<Themes
													data={smartlinkFormData}
													changeField={changeField}
												/>
											</div>
											<ModalControls
												willUnmount={willUnmount}
												url={smartlinkFormData.url}
											/>
										</>
									)}
								</div>
							</div>
						</Container>
					</Modal>,
				]}
			</PoseGroup>
		</BaseModal>
	);
};

export default SmartlinkModal;
