// Core
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Icons
import copy_icon from 'images/copy_icon.svg';
import copy_icon_dark from 'images/copy_icon_dark.svg';

// Styles
import styles from './CopyButton.module.css';

const CopyButton = ({ data, dark }) => {
	const [isCopied, setIsCopied] = useState(false);

	const handleClipboard = (e) => {
		e.stopPropagation();
		navigator.clipboard.writeText(data ?? '');
		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 1000);
	};

	return (
		<button
			id="copy_button"
			className={styles.copyButton}
			onClick={handleClipboard}
		>
			<img src={dark ? copy_icon_dark : copy_icon} alt="" />
			{isCopied && (
				<span className={styles.copiedTooltip}>
					<FormattedMessage id="rod.statistic.trends.copied" />
				</span>
			)}
		</button>
	);
};

export default CopyButton;
