export const outletsLabels = [
	{ keyword: 'amazon', label: 'amazon' },
	{ keyword: 'apple', label: 'apple' },
	{ keyword: 'audiblemagic', label: 'Audible Magic' },
	{ keyword: 'bandcamp', label: 'bandcamp' },
	{ keyword: 'deezer', label: 'deezer' },
	{ keyword: 'facebook', label: 'facebook' },
	{ keyword: 'iheart', label: 'iHeart Radio' },
	{ keyword: 'itunes', label: 'iTunes' },
	{ keyword: 'instagram', label: 'instagram' },
	{ keyword: 'joox', label: 'JOOX' },
	{ keyword: 'spotify', label: 'spotify' },
	{ keyword: 'tidal', label: 'tidal' },
	{ keyword: 'tiktok', label: 'tiktok' },
	{ keyword: 'vkmusic', label: 'VK music' },
	{ keyword: 'xiami', label: 'Xiami Music' },
	{ keyword: 'yandex', label: 'yandex' },
	{ keyword: 'youtube', label: 'YouTube' },
	{ keyword: 'zvuk', label: 'zvuk' },
];
