// Icons
import pencil from 'images/pencil.svg';
import diagram from 'images/diagram_button.svg';
import preview from 'images/preview.svg';

// Styles
import styles from './CustomButton.module.css';

const CustomButton = ({ onHandle, mode, disable }) => {
	return (
		<button
			className={
				disable
					? `${styles.customButton} ${styles.disable}`
					: styles.customButton
			}
			onClick={onHandle}
			disabled={disable}
		>
			<img
				src={mode === 'edit' ? pencil : mode === 'preview' ? preview : diagram}
				alt=""
			/>
		</button>
	);
};

export default CustomButton;
