// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import { Button } from 'components/Buttons/Button';

// Styles
import styles from './ModalControls.module.css';

const ModalControls = ({ willUnmount, url }) => {
	return (
		<div className={styles.controls}>
			<Button
				text={<FormattedMessage id="rod.smartlink_form.controls.preview" />}
				variant="primary"
				onClick={() => window.open(url, '_blank')}
				className={styles.controlButton}
			/>
			<Button
				text={<FormattedMessage id="rod.smartlink_form.controls.done" />}
				variant="primary"
				onClick={(e) => {
					willUnmount(e);
				}}
				className={styles.controlButton}
			/>
		</div>
	);
};

export default ModalControls;
