export const smartlinkThemes = [
	{
		id: 'dark',
		title_en: 'dark',
		title_ru: 'темная',
	},
	{
		id: 'light',
		title_en: 'light',
		title_ru: 'светлая',
	},
];
