// Core
import { useState } from 'react';

// Icons
import remove_cart from 'images/remove_cart.svg';
import remove_cart_red from 'images/remove_cart_red.svg';

// Styles
import styles from './DeleteButton.module.css';

const DeleteButton = ({ onDeleteHandler, disable }) => {
	const [icon, setIcon] = useState(remove_cart);

	return (
		<button
			className={disable
				? `${styles.button} ${styles.disable}`
				: styles.button}
			onClick={onDeleteHandler}
			onMouseEnter={() => setIcon(remove_cart_red)}
			onMouseLeave={() => setIcon(remove_cart)}
			disabled={disable}
		>
			<img src={icon} alt="Delete" />
		</button>
	);
};

export default DeleteButton;
