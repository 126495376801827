// Core
import { FormattedMessage } from 'react-intl';

// UI
import { SwitchSave } from 'material-design/MuiStyled/MuiStyled';

// Styles
import styles from './Socials.module.css';
import LinkItem from '../LinkItem/LinkItem';

const Socials = ({
	data,
	handleSwitcher,
	updateSocialLink,
	deleteSocialLink,
}) => {
	return (
		<div className={styles.container}>
			<div className={styles.switcherContainer}>
				<h4 className={styles.switcher__title}>
					<FormattedMessage id="rod.smartlink_form.socials" />
				</h4>
				<div className={styles.switcher__toggler}>
					<SwitchSave
						checked={data?.social_links_enabled}
						onChange={(_, c) => handleSwitcher(c)}
					/>
				</div>
			</div>
			{data?.social_links_enabled &&
				(() => {
					const socialLinks = [
						{
							key: 'artist_official',
							label: 'official',
							url: data.artist_official,
						},
						{
							key: 'artist_twitter',
							label: 'twitter',
							url: data.artist_twitter,
						},
						{
							key: 'artist_facebook',
							label: 'facebook',
							url: data.artist_facebook,
						},
						{
							key: 'artist_instagram',
							label: 'instagram',
							url: data.artist_instagram,
						},
						{
							key: 'artist_youtube',
							label: 'youtube',
							url: data.artist_youtube,
						},
						{ key: 'artist_vk', label: 'vk', url: data.artist_vk },
					].filter((link) => link.url);

					return socialLinks.length > 0 ? (
						<div className={styles.linksContainer}>
							{socialLinks.map((item, index) => (
								<LinkItem
									key={index}
									label={item.label}
									link={item.url}
									updateSocialLink={(newUrl) =>
										updateSocialLink(item.key, newUrl)
									}
									deleteSocialLink={() => deleteSocialLink(item.key)}
								/>
							))}
						</div>
					) : null;
				})()}
		</div>
	);
};

export default Socials;
